import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight)
document.body.appendChild(renderer.domElement);
const scene = new THREE.Scene;
const camera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
const orbit = new OrbitControls(camera, renderer.domElement);
orbit.update()
const axesHelper = new THREE.AxesHelper(3);
//scene.add(axesHelper);
camera.position.z=-10;
camera.position.y=10;
camera.position.x=10;
const boxGeometry = new THREE.BoxGeometry();
const boxMaterial = new THREE.MeshBasicMaterial({color:0xe31f26});
const box = new THREE.Mesh(boxGeometry,boxMaterial);
scene.add(box);
box.rotation.x=45;
box.rotation.y=45;
box.rotation.z=45;
const planexGeometry = new THREE.PlaneGeometry(10 , 10);
const planexMaterial = new THREE.MeshBasicMaterial({color: 0xFFFFFF, side:THREE.DoubleSide});
const planex = new THREE.Mesh(planexGeometry,planexMaterial);
//scene.add(planex);
planex.rotation.x=-0.5 * Math.PI;
const gridHelper = new THREE.GridHelper(10,10);
scene.add(gridHelper);
function animate()
{
    box.rotation.x += 0.01;
    box.rotation.y += 0.01;
    box.rotation.z += 0.01;
    renderer.render(scene, camera);
}
renderer.setAnimationLoop(animate);
//renderer.render(scene, camera);

